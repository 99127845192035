import React from 'react';
import {StaticImage} from 'gatsby-plugin-image';
import Button from '../components/Button';

export default function Landing() {
    return (
        <div id="Home" className="landing-container">
            <StaticImage
                src='../images/CastlHome.jpg'
                alt="landing page image" 
                layout="constrained"
                loading="eager"
                placeholder="DOMINANT_COLOR"
                objectFit="cover"
                className="landing-image-container"
                objectPosition= "50% 15%"
            />
            <div className="landing-text-container">
                <StaticImage
                    src="../images/white_blueprints.jpg"
                    alt="white blueprints"
                    layout="constrained"
                    loading="eager"
                    placeholder="DOMINANT_COLOR"
                    objectFit="cover"
                    objectPosition="50% 50%"
                    className="image-container"
                />
                <div className="image-overlay"/>
                <h1>Turning Your Home Into Your Castle</h1>
                <h2>PROUDLY SERVING VIRGINIA, MARYLAND, AND D.C.</h2>
                <div className="desktop-button"><Button buttonType="2" linkType="gatsby" to="/contact" text="LETS GET BUILDING &thinsp; &rarr;" /></div>
                <div className="mobile-button"><Button buttonType="3" linkType="gatsby" to="/contact" text="LETS GET BUILDING &thinsp; &rarr;" /></div>
            </div>
        </div>
    )
}
