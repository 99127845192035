import React from 'react';
import {useState} from 'react';
import {graphql, useStaticQuery, navigate} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';


export default function ServiceCards() {

    const data = useStaticQuery(graphql`
        query serviceImages{
            allFile(
                filter: {relativeDirectory: {eq: "serviceImages"}}
                sort: {order: ASC, fields: name}
              ) {
                edges {
                  node {
                    childImageSharp {
                      fields {
                        exif {
                          raw {
                            image {
                              ImageDescription
                            }
                          }
                        }
                      }
                      gatsbyImageData(
                        formats: AUTO
                        height: 350
                        layout: CONSTRAINED
                        placeholder: DOMINANT_COLOR
                        width: 575
                      )
                    }
                    name
                  }
                }
              }
        }
    `);

    const [serviceIndex, setIndex] = useState(0);

    const handleClick = (e) => {

      // Once i write content for this uncomment it

      // if(e.target.id === 'service0'){
      //   navigate('/services/additions_and_renovations');
      // }
      // else if(e.target.id === 'service1'){
      //   navigate('/services/basements');
      // }
      // else if(e.target.id === 'service2'){
      //   navigate('/services/bathrooms');
      // }
      // else if(e.target.id === 'service3'){
      //   navigate('/services/decks_and_fences');
      // }
      // else if(e.target.id === 'service4'){
      //   navigate('/services/flooring')
      // }
      // else if(e.target.id === 'service5'){
      //   navigate('/services/framing_and_drywall')
      // }
      // else if(e.target.id === 'service6'){
      //   navigate('/services/kitchens')
      // }
      // else if(e.target.id === 'service7'){
      //   navigate('/services/painting')
      // }
      // else if(e.target.id === 'service8'){
      //   navigate('/services/roofing_siding_gutters')
      // }
      // else if(e.target.id === 'service9'){
      //   navigate('/services/stonework_and_concrete');
      // }
      // else if(e.target.id === 'service10'){
      //   navigate('/services/windows_and_doors')
      // }
      // else if(e.target.id === 'service11'){
      //   navigate('/services/and_more')
      // }
      // else{
        
      // }
      
    };

    return (
        <div className="service-card-container">
            {data.allFile.edges.map((edge, index) => {
                return(
                    <div className="card-container">
                        <div id={`service${index}`} className="service-card-overlay" onClick={handleClick}>
                          <h1>{(edge.node.name.charAt(0) !== 'z') ? edge.node.name : edge.node.name.substring(1)}</h1>
                        </div>
                        <GatsbyImage className="service-image" image={getImage(edge.node)} alt={edge.node.childImageSharp.fields.exif.raw.image.ImageDescription}/>
                        <div className="bottom-img-line"/>
                        <div className="service-image-text-container">
                            <h2 className="service-number">{(index < 9) ? `0${index+1}` : index+1}</h2>
                            <h2 className="service-title">{(edge.node.name.charAt(0) !== 'z') ? edge.node.name : edge.node.name.substring(1)}</h2>
                        </div>
                    </div>
                );
            })}
        </div>
    )
}
